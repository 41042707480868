import React, { useState ,useEffect } from 'react';
import { useLocation } from "react-router-dom";
import firebase from "@firebase/app";
import {Button, Card} from "react-bootstrap";
import { Input } from "reactstrap";
import Loader from "react-loader-spinner";
import {getFormQuestions , sendAnswers} from "./../services/PredictiveDialerService"

const FormGenerator = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumber = searchParams.get("phoneNumber");
  const agentId = searchParams.get("pdagent");
  //state
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [questionData, setQuestionData] = useState(null);
  const [FormLoading, setFormLoading] = useState(false);
  const [isVisibleForm, setVisibleForm] = useState(true);

  function onChange(user) {
    if (!user) {
      window.location.href = "/";
    }
    setLoading(false);
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  const loadFormQuestions = async () => {
    if (!loading) {
      setFormLoading(true);
      const res = await getFormQuestions(phoneNumber);
      console.log(res);
      setQuestionData(res);
      setFormLoading(false);
    }
  };

  useEffect(async () => {
    loadFormQuestions();
  }, [loading]);

  const handleAnswer = (questionId, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );
      if (existingAnswerIndex >= 0) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = { questionId, answer: value };
        return updatedAnswers;
      } else {
        return [...prevAnswers, { questionId, answer: value }];
      }
    });
  };

  const handleActionSelection = (e) => {
    const { value, checked } = e.target;
    var actionList = [];
    actionList.push(value);
    setSelectedActions(actionList);
   
  };

  const submitAnswers = async () => {
    const newArray = answers.map((obj) => {
      if (Array.isArray(obj.answer)) {
        obj.answer = obj.answer.join(",");
      }
      return obj;
    });
    setFormLoading(true);
    var payload = {
      agentId: agentId,
      leadPhoneNumber: phoneNumber,
      answers: newArray,
      actions: selectedActions,
    };
    var res = await sendAnswers(payload);
    setVisibleForm(!res);
    setFormLoading(false);
  };

  const renderQuestion = (question) => {
    switch (question.answerType) {
      case "text":
        return (
          <div key={question.questionId}>
            <label
              style={{ marginTop: 10, color: "black", fontSize: 15 }}
              htmlFor={question.questionId}
            >
              {question.questionText}
            </label>
            <Input
              id={question.questionId}
              onChange={(event) =>
                handleAnswer(question.questionId, event.target.value)
              }
            />
          </div>
        );
      case "single_choice":
        return (
          <div key={question.questionId}>
            <label style={{ marginTop: 30, color: "black", fontSize: 15 }}>
              {question.questionText}
            </label>
            <br />
            <select
              style={{width: '100%', height: 40, border: '1px solid #E3E3E3'}}
              id={question.questionId}
              onChange={(event) =>
                handleAnswer(question.questionId, event.target.value)
              }
            >
              <option key={"0"} value={""}>
                {""}
              </option>
              {question.answerChoices.map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
          </div>
        );
      case "multiple_choices":
        return (
          <div key={question.questionId}>
            <label style={{ marginTop: 30, color: "black", fontSize: 15 }}>
              {question.questionText}
            </label>
            {question.answerChoices.map((choice) => (
              <div key={choice}>
                <input
                  type="checkbox"
                  id={`${question.questionId}-${choice}`}
                  name={question.question}
                  style={{ transform: 'scale(2)', marginLeft: '5px' }}
                  value={choice}
                  onChange={(event) =>
                    handleAnswer(
                      question.questionId,
                      event.target.checked
                        ? [
                            ...(answers[
                              answers.findIndex(
                                (answer) =>
                                  answer.questionId === question.questionId
                              )
                            ]?.answer || []),
                            choice,
                          ]
                        : answers[
                            answers.findIndex(
                              (answer) =>
                                answer.questionId === question.questionId
                            )
                          ]?.answer?.filter((answer) => answer !== choice)
                    )
                  }
                />
                <label
                  style={{ marginLeft: 10 }}
                  htmlFor={`${question.questionId}-${choice}`}
                >
                  {choice}
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (loading || FormLoading) {
    return (
      <div style={{ flex: 1, textAlign: "center" }}>
        <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  if (!questionData) {
    console.log("No data");
    return null;
  }

  if (isVisibleForm) {
    return (
      <div
        style={{
          border: "1px solid #eee",
          width: 700,
          padding: 20,
          position: "absolute",
          paddingBottom: 80,
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            backgroundColor: "purple",
            color: "white",
            borderRadius: 5,
            padding: 8,
          }}
        >
          <h3 style={{ margin: 10 }}>{questionData.script}</h3>
        </div>
        <div>
          <Card className="card-plain table-plain-bg">
            {questionData.questions.map((question) => renderQuestion(question))}
          </Card>
        </div>

        <select
          style={{ width: "100%", height: 40, border: "1px solid #E3E3E3" }}
          onChange={handleActionSelection}
        >
          <option key={"0"} value={""}>
            {""}
          </option>
          {questionData.actions.map((choice) => (
            <option key={choice.action} value={choice.action}>
              {choice.actionName}
            </option>
          ))}
        </select>
        <br></br>
        <br></br>
        <Button
          style={{
            width: "100%",
            background: "green",
            color: "white",
            border: 0,
          }}
          onClick={() => submitAnswers()}
        >
          envoyer
        </Button>
      </div>
    );
  }
};

export default FormGenerator;