export default {
  API_URL: "https://api.wafr.co/api/",
  DEV_API_URL: "https://staging.wafr.co/api/",
  MOROCCO_PHONE_REGEX:/^(\+212|0)([ ]*)(\d[ ]*){9}$/,
  operations:{
    "baraka_deposit": "Dépôt Al Baraka",
    "cashplus_deposit": "Dépôt Cashplus",
    "ClientPurchase":"Achat client",
    "CashBackTransfer":"Cashback",
    "RefundTransfer":"Remboursement recharge",
    "RetailerPurchase":"Achat epicier",
    "TopupPurchase":"Achat de recharge",
    "TransferFees":"Frais de transfer",
    "Transfer":"Transfert",
    "REWARD_USER_ACTIVITION":"Activation d'un client",
    "REWARD_NEW_RETAILER_BONUS":"Bonus inscription épicier",
    "REWARD_FMCG_USER_PRODUCT_SOLD":"Cashback produits vendu",
    "REWARD_CLIENT_BONUS":"Premier achat",
    "REWARD_FIRST_DEPOSIT":"Premier Dépôt",
    "REWARD_REFEREE_REWARD":"Parrainage",
    "REWARD_REFERRER_REWARD":"Parrainage",
    "REWARD_USER_ACTIVATION_FMCG_AGENT":"Activation du client",
    "REWARD_RETAILER_REFERRAL_REFERRER_REWARD":"Parrainage épicier",
    "REWARD_RETAILER_REFERRAL_REFFEREE_REWARD":"Parrainage épicier"  
  },
  Fraud : [
    "BLOCK_REASON_GENERAL_FRAUD",
    "BLOCK_REASON_IMEI_FRAUD"
  ],
  FraudsterStatus : [
    "Non",
    "Oui",
    "Pas intéressé",
    "Doute",
    "NRP 1",
    "NRP 2",
    "NRP 3",
    "NRP 4",
    "NRP 5",
    "Injoignable",
    "Intéressé",
  ]
};
