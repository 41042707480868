import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/auth";
function LoginForm() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log("auth state changed user is logged in ");
      console.log(user);
      window.location.href = "/admin/Users";
    }
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username && password) {
      try {
        const creds = await firebase
          .auth()
          .signInWithEmailAndPassword(username, password);
        console.log(creds.user);
        window.location.href = "/admin/Users";
      } catch (e) {
        console.log("Error occured while signing in");
        setError(e.message);
      }
    }
    return false;
  };

  return (
    <>
      <Container fluid>
        <div className="row justify-content-center" style={{marginTop: 50}}>
          <Col md="5">
            <Card>
              <Card.Header style={{textAlign: "center"}}>
                <Card.Title as="h4">Authentification</Card.Title>
              </Card.Header>
              <Card.Body style={{textAlign: "center"}}>
                <Form>
                  <div className="row justify-content-center">
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </div>
                  <div className="row justify-content-center">
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Mot de passe</label>
                        <Form.Control
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          required
                        ></Form.Control>
                        <label>{error}</label>
                      </Form.Group>
                    </Col>
                  </div>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    onClick={(e) => handleLogin(e)}
                  >
                    Se connecter
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
}
export default LoginForm;
