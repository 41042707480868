import React, { useState, useEffect } from "react";
import {Card, Container,Col,Row,Button,Table} from "react-bootstrap";
import firebase from "@firebase/app";
import { Input } from "reactstrap";
import Loader from "react-loader-spinner";
import {getCashinRequest} from "../services/cashinService";
import CashinRequestTable from "../components/cashin/CashinRequestTable"
import { set } from "date-fns";


function CashinDemandView() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [CashinLoading, setCashinLoadingLoading] = useState(false);

    function onChange(user) {
      if (!user) {
        window.location.href = "/";
      }
      setLoading(false);
    }

    React.useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
      return () => unsubscribe();
    }, []);

    const loadCashinRequest = async () => {
      if (!loading) {
        setCashinLoadingLoading(true)
        const res = await getCashinRequest();
        setData(res.deposits);
        setCashinLoadingLoading(false)
  
      }
    }

    useEffect(async () => {
        loadCashinRequest();
    }, [loading]);


  if(loading || CashinLoading){ return (
      <div style={{ flex: 1, textAlign: "center" }}>
        <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

    if (!data) {
      console.log("No data");
      return null;
    }
  return (
    <Container fluid>
      <CashinRequestTable data={data}  refresh={loadCashinRequest}/>
    </Container>
  );
                
}

export default CashinDemandView;
