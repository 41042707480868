import React,{useState} from "react";
import {
    Button,
    Modal,
    Alert,
  } from "react-bootstrap";
  import { Input, Label } from "reactstrap";
  
  export const AddCashinRequestModal = ({show, phoneNumber,id,receipt, handleClose, handleClick}) => {
    const [name, setName] = useState(null);
    return (
      <>
        <Modal
          style={{textAlign:"center"}}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size='lg'
        >
          <Modal.Header  closeButton>
            <Modal.Title>Envoyer le libelle du client {phoneNumber} sur le reçu de dépôt </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <img style={{marginBottom:10}}  src={receipt} height="500" width="600" />
            <Input value={name} placeholder={'entrez le libelle de deposit'} onChange={(e) => {
                    setName(e.target.value);
                  }}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>annulez</Button>
            <Button variant="primary" onClick={()=>handleClick(name,id)}>envoyez</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  