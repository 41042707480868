import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import constants from "./../constants";
import { toStringFormat } from "./Utils";


export const getCashinRequest= async () => {
  const token = await firebase.auth().currentUser.getIdToken();
  let url = constants.API_URL;
  try {
    const response = await axios.get(`Deposit/NewDeposits`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });
    if (response.status === 200 && response.data.success == true) {
      return response.data;
    }
    else{
        alert('Une erreur , réessayez')
        return null
    }
  } catch (e) {
    alert('Une erreur , réessayez')
    return null;
  }

  return null;
};

export const sendRefCashin= async (id , ref) => {
  const token = await firebase.auth().currentUser.getIdToken();
    var payload = {
      "depositId": id,
      "ref": ref
    }
    console.log(payload);
    let url = constants.API_URL;
    try {
      const response = await axios.post(`Deposit/UpdateRef`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      });
      console.log(response.data)
      if (response.status === 200 && response.data.success == true) {
        return null
      }
      else{
          alert('Une erreur , réessayez')
          return null
      }
    } catch (e) {
      alert('Une erreur , réessayez')
      console.log(e)
      return null;
    }

    return null;
};
