import React, { useState, useEffect } from "react";
import firebase from "@firebase/app";
import "@firebase/auth";
import OperationsTable from "../components/Operations/OperationsTable";
import {
    deleteImeis,
    getUser,
    blockUser,
    unblockUser,
    EnableRetailerRelocationButton,
    VerifyRetailer
    } from "../services/UserService";
import "react-datepicker/dist/react-datepicker.css";
import {Card, Container,Col,Row,Button,Table} from "react-bootstrap";
import { Input } from "reactstrap";
import {ConfirmBlockModal} from "../components/Modals/ConfirmBlockModal";
import {ConfirmRelocationModal} from "../components/Modals/ConfirmRelocationModal";
import {ConfirmVerifyRetailerModal} from "../components/Modals/ConfirmVerifyRetailerModal";
import {ConfirmDeleteImeisModal} from "../components/Modals/ConfirmDeleteImeisModal";
import {toDateTimeFormat} from "../services/Utils";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../assets/css/table.css"
import constants from "../constants";

function UserListView() {
  const [user, setUser] = useState(null);
  const [imeiToDelete, setImeiToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmBlockModal, setShowConfirmBlockModal] = useState(false);
  const [showConfirmRelocationModal, setShowConfirmRelocationModal] = useState(false);
  const [showConfirmRetailerVerificationModal, setShowConfirmRetailerVerificationModal] = useState(false);
  const [showConfirmDeleteImiesModal, setShowConfirmDeleteImiesModal] = useState(false);

  const [userLoading, setUserLoading] = useState(false);
  const [uid, setUid] = useState('');

  const handleShowConfirmBlockModal = () => {
    setShowConfirmBlockModal(true)
  };
  const handleShowConfirmRelocationModal = () => {
    setShowConfirmRelocationModal(true)
  };
  
  const handleShowRetailerVerificationModal = () => {
    setShowConfirmRetailerVerificationModal(true)
  };

  const handleshowConfirmDeleteImiesModal = (imei) => {
    if (imei && imei.id)
      setImeiToDelete(imei)
    else
      setImeiToDelete(null)
    setShowConfirmDeleteImiesModal(true)
  };

  function onChange(user) {
    if (!user) {
      window.location.href = "/";
    }
    setLoading(false);
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  const validatePhoneNumber = phoneNumber => {
    return constants.MOROCCO_PHONE_REGEX.test(phoneNumber);
  };

  const formatPhoneNumber = (number) => {
    if (!number) return number;
    if (number.startsWith("0")) {
      return "+212" + number.substr(1);
    }
    return number;
  };

  useEffect(async () => {
    if (!loading) {
      console.log(window.location)
      let url = new URL(window.location);
      let urlsp = url.searchParams;
      let phoneNumber = urlsp.get("uid")
      if (phoneNumber) {
        if (phoneNumber.startsWith(' '))
          phoneNumber = '+' + phoneNumber.trim()
        loadUser(phoneNumber)
      }
    }
  }, [loading]);

  const loadUser = async (phoneNumber) => {
    if (!validatePhoneNumber(phoneNumber)) {
      alert("invalid phone number")
      return; 
    }
    phoneNumber = formatPhoneNumber(phoneNumber)
    if (!loading) {
      setUserLoading(true);
      const user = await getUser(phoneNumber);
      console.log(user)
      setUser(user);
      setUserLoading(false);
    }
  }

  const handleCloseConfirmBlockModal = () => setShowConfirmBlockModal(false);
  const handleCloseConfirmRelocationModal = () => setShowConfirmRelocationModal(false);
  const handleCloseConfirmRetailerVerificationModal = () => setShowConfirmRetailerVerificationModal(false);

  const handleCloseConfirmDeleteImeisModal = () => setShowConfirmDeleteImiesModal(false);

  const handleBlockConfirmClick = async (user, blockHours, blockImei, note, reason) => {
    setShowConfirmBlockModal(false);
    let status = false;
    if (user.status == 'BLOCKED')
      status = await unblockUser(user.phoneNumber);
    else
      status = await blockUser(user.phoneNumber, blockHours, blockImei, note, reason);
    if (status)
      loadUser(user.phoneNumber)
  }

  const handleRetailerVerificationConfirmClick = async (user) => {
    setShowConfirmRetailerVerificationModal(false);
    let status = await VerifyRetailer(user.phoneNumber);
    if (status) {
      alert(user.phoneNumber + ' is confirmed')
      await loadUser(user.phoneNumber)
    }
  }

  const handleRelocationConfirmClick = async (user) => {
    setShowConfirmRelocationModal(false);
    let status = await EnableRetailerRelocationButton(user.phoneNumber);
    if (status)
      alert("relocation button is enabled for retailer" + user.phoneNumber)
  }

  const handleDeleteImeis = async (user, imei) => {
    setShowConfirmDeleteImiesModal(false);
    let status = false;
      status = await deleteImeis(user.phoneNumber, imei?.id);
    if (status)
      loadUser(user.phoneNumber)
  }

  const actionButton = (user) => {
    if (user.status == "ACTIVE")
      return <Button Button style={{float:'right' , marginLeft : 8}} onClick={handleShowConfirmBlockModal} variant="danger" >block</Button>
    if (user.status == "BLOCKED")
      return <Button Button style={{float:'right' , marginLeft : 8}} onClick={handleShowConfirmBlockModal} variant="success" >unblock</Button>
  }

  const renderRetailerButtons = (user) => {
    if (user.status == 'ACTIVE' && user.accountType == 'RETAILER') {
      let relocationButton = <Button style={{margin:"0px 20px"}} onClick={handleShowConfirmRelocationModal} variant="primary" >Enable Relocation</Button>
      let verifyButton = <Button onClick={handleShowRetailerVerificationModal} variant="success" >Verify</Button>
      let array = [relocationButton];
      if (!user.verified)
        array = [relocationButton,verifyButton]
      return array.map((x,idx) => <span index={idx}>{x}</span>);
    }
    return <></>
  }

  const renderUserInfo = (user) => 
  {
    if (loading || userLoading) {
      return (
        <div style={{ flex: 1, textAlign: "center" }}>
          <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
        </div>
      );
    }
    if (!user)
      return <></>
    return <>
      <Row>
      <Col md="12">
        <Card className="card-plain table-plain-bg">
          <Card.Body className="table-full-width table-responsive px-0">
            <Table id="invoice">
              <thead>
                <tr>
                  <th className="border-0">numéro de téléphone</th>
                  <th className="border-0">Type de compte</th>
                  <th className="border-0">Statut</th>
                  <th className="border-0">statut de kyc</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>{user.phoneNumber}</td>
                    <td>{user.accountType}</td>
                    <td>{user.status}</td>
                    <td>{user.kycStatus}</td>
                  </tr>
              </tbody>
            </Table>
            <Table id="invoice">
              <thead>
                <tr>
                  <th className="border-0">le solde</th>
                  <th className="border-0">point fidélité</th>
                  <th className="border-0">Statut de fidélité</th>
                  <th className="border-0">statut de solde</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>{user.walletPointsAmount}</td>
                    <td>{user.loyaltyPointsAmount}</td>
                    <td>{user.loyaltyPointsBlocked ? 'bloquée' : 'active'}</td>
                    <td>{user.walletPointsLocked ? 'bloquée' : 'active'}</td>
                  </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <OperationsTable operations={user.operations} />
    </>
  }

  return (
    <Container fluid>
      <ConfirmBlockModal
        show={showConfirmBlockModal}
        handleClose={handleCloseConfirmBlockModal}
        handleClick={handleBlockConfirmClick}
        user={user}
      />
      <ConfirmRelocationModal
        show={showConfirmRelocationModal}
        handleClose={handleCloseConfirmRelocationModal}
        handleClick={handleRelocationConfirmClick}
        user={user}
      />
      <ConfirmVerifyRetailerModal
        show={showConfirmRetailerVerificationModal}
        handleClose={handleCloseConfirmRetailerVerificationModal}
        handleClick={handleRetailerVerificationConfirmClick}
        user={user}
      />
      <ConfirmDeleteImeisModal
        show={showConfirmDeleteImiesModal}
        handleClose={handleCloseConfirmDeleteImeisModal}
        handleClick={handleDeleteImeis}
        user={user}
        imei={imeiToDelete}
      />
      <Row>
        <Col md="12">
          <Card className="card-plain table-plain-bg">
            <Card.Header>
              <Row style={{ alignItems: "flex-end" }}>
                <Col md="4">
                  <label htmlFor="name">Phone number</label>
                  <Input value={uid} onChange={(e) => {
                    setUid(e.target.value);
                  }}/>
                </Col>
                <Col md="3">
                  <Button onClick={() => {
                    loadUser(uid);
                  }} variant="success" >Search</Button>
                </Col>
              </Row>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      {renderUserInfo(user)}
    </Container>
  );
}

export default UserListView;
