import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export const getUser = async (phoneNumber) => {
  const token = await firebase.auth().currentUser.getIdToken();

  let url = `/v1/WafrConsole/UserInfo?phoneNumber=${encodeURIComponent(
    phoneNumber
  )}`;
  
  console.log(token);
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data || !data.success) 
      {
        alert(data.error)
        console.log("data = " , data)
        return false;
      }
      return data;
    }
  } catch (e) {
    console.log("Error while getting User");
    return null;
  }
  return [];
};


export const ResolveRetailer = async (phoneNumber) => {
  const token = await firebase.auth().currentUser.getIdToken();
  let url = `WafrConsole/Resolve?phoneNumber=${encodeURIComponent(phoneNumber)}`;
  try {
    const response = await axios.post(url, {} ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data.success) {
        alert("did not resolve retailer account");
      }
      return data.success
    }
  } catch (e) {
    console.log("Error while resolve retailer");
    return null;
  }
  return [];
}

export const blockUser = async (phoneNumber, blockHours, blockImei = false, note, blockReason) => {
  
  let payload = {
    phoneNumber,
    blockHours,
    blockImei,
    note,
    blockReason
  }

  const token = await firebase.auth().currentUser.getIdToken();
  let url = "WafrConsole/blockUser";
  try {
    const response = await axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data.success) alert(data.error);
      return data.success;
    }
  } catch (e) {
    alert("Error while blocking user " + phoneNumber);
    return null;
  }
  return null;
};

export const unblockUser = async (phoneNumber) => {
  const token = await firebase.auth().currentUser.getIdToken();
  let url = `WafrConsole/UnblockUser`;
  try {
    const response = await axios.post(
      url,
      { phoneNumber: phoneNumber },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    if (!(response.status === 200 && data && data.success))
      alert("Error while unblocking user " + phoneNumber);
    return true;
  } catch (e) {
    alert("Error while unblocking user " + phoneNumber);
    return null;
  }
};

export const EnableRetailerRelocationButton = async (phoneNumber) => {
  let payload = {
    phoneNumber,
  };

  const token = await firebase.auth().currentUser.getIdToken();
  let url = "WafrConsole/EnableRelocation";
  try {
    const response = await axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data.success)
        alert(
          "Error while enabling relocation button for retailer " + phoneNumber
        );
      return data.success;
    }
  } catch (e) {
    alert("Error while enabling relocation button for retailer " + phoneNumber);
    return null;
  }
  return null;
};

export const VerifyRetailer = async (phoneNumber) => {
  
  let payload = {
    phoneNumber
  }

  const token = await firebase.auth().currentUser.getIdToken();
  let url = "WafrConsole/VerifyRetailer";
  try {
    const response = await axios.post(url, payload,
        { headers: { Authorization: `Bearer ${token}` }});
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data.success)
        alert("Error while Verifying Retailer " + phoneNumber);
      return data.success
    }
  } catch (e) {
        alert("Error while Verifying Retailer " + phoneNumber);
    return null;
  }
  return null;
};
  export const deleteImeis = async (phoneNumber, imeiId) => {
      const token = await firebase.auth().currentUser.getIdToken();
      let url = `WafrConsole/ClearImeiList?phoneNumber=${encodeURIComponent(phoneNumber)}`;
      if (imeiId)
        url += `&imeiId=${imeiId}`;
      try {
        const response = await axios.post(url, {},
            { headers: { Authorization: `Bearer ${token}` }});
        if (response.status === 200 && response.data) {
          const data = response.data;
          if (!data.success)
            alert(data.error)
          return data.success
        }
      } catch (e) {
            alert(`Error while deleting user ${phoneNumber} imeis`);
        return null;
      }
      return null;
  };
