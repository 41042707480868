import {
  Button,
  Modal,
  Alert
} from "react-bootstrap";
import React from "react";

export const ConfirmVerifyRetailerModal = ({show, user, handleClose, handleClick}) => {

  if (!user)
    return<></>;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Verifying retailer {user.phoneNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='success'>
            the retailer {user.phoneNumber} will be set as verified
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={()=>handleClick(user)}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
