import React,{useState} from "react";
import {
  Card,
  Table,
  Row,
  Col,
  Dropdown
} from "react-bootstrap";
import "../../assets/css/table.css"
import constants from "../../constants";

const translate = (type) => {
  let value = constants.operations[type];
  if (!value)
    return type;
  return value;
}

const toDateFormat = (timestamp) => {
  const date = new Date(parseInt(timestamp));
  return date.toLocaleTimeString("fr-Fr", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};


var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MAD',
});
const format = (price) => formatter.format(price)

function OperationsTable({operations}) {
  if (!operations) {
    return null;
  }
  let types = operations.map(x => translate(x.operationLabel)) || []
  types = types.filter((value, index, self) => self.indexOf(value) === index);
  let filters = []
  types.forEach(element => {
    filters.push({"value" : element, "checked" : true})
  });

  const [selected, setSelected] = useState(filters);

  const update = (checked, index) => {
    let list = [...selected]
    selected[index].checked = checked
    setSelected(list);
  }

  return (
    <>
        <Row col="12" style={{ alignItems: "flex-end" }}>
          <Col md="10">
            <Card key="1"   style={{ backgroundColor:"#cce5ff", color:"white", border:"0px" }} className="mb-2">
              <Card.Body style={{
                    boxShadow:"0 4px 20px 0 rgba(0,0,0,.14),0 7px 10px -5px rgba(255,152,0,.4)",
                    background:"linear-gradient(60deg,#ffa726,#fb8c00)"
                  }}>
                <Card.Text style={{fontSize: 22,fontWeight: 400}}>Operations</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md="2" style={{paddingLeft:2}}>
            <Dropdown style={{marginBottom:12}} >
              <Dropdown.Toggle variant="success" id="dropdown-basic">filters</Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  selected.map((type, index) => {
                    return <Dropdown.Item key={index} eventKey={index} >
                              <input key={Math.random()} type="checkbox" checked={type.checked} onChange={(e) => update(e.target.checked, index)}/>
                              {type.value}
                          </Dropdown.Item>
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Body className="table-full-width table-responsive px-0">
                <Table id="invoice">
                  <thead>
                    <tr>
                      <th className="border-0">Date</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">Type</th>
                      <th className="border-0">Destination</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operations.map((operation, index) => {
                      let item = selected.find(x => x.value == translate(operation.operationLabel))
                      if (item && !item.checked)
                        return ;
                      let color = 'red', destination = 'N/A'
                      if (operation.isCredit)
                        color = 'green';
                      if (
                        operation &&
                        operation.otherUserPhoneNumber &&
                        operation.otherUserPhoneNumber.startsWith('+212')
                      )
                        destination = operation.otherUserPhoneNumber;
                      return (
                        <tr key={index}>
                          <td>{toDateFormat(operation.transactionDate)}</td>
                          <td style={{color}}>{format(operation.amount)}</td>
                          <td>{translate(operation.operationLabel)}</td>
                          <td>{destination}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </>
  );
}

export default OperationsTable;
