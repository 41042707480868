import React,{useState} from "react";
import {
  Card,
  Table,
  Row,
  Col,
  Button
} from "react-bootstrap";
import "../../assets/css/table.css"
import constants from "../../constants";
import {AddCashinRequestModal} from "../Modals/AddCashinRequestModal";
import {sendRefCashin} from "../../services/cashinService";

const toDateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
};


const urlFormat = (path) =>{
  var kycUrl = constants.KYC_API;
  return kycUrl.replace('/api','')+path.replace('uploads\\','')
}

const formatPhoneNumber = (number) => {
    if (!number) return number;
    if (number.startsWith("+212")) {
      return number.replace("+212","0");
    }
    return number;
  };

function CashinRequestTable({data , refresh}) {
    const [showAddCashinRequestModal, setShowAddCashinRequestModal] = useState(false);
    const [id, setId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [receipt, setReceipt] = useState(null);

    
    const handleCancelCashinModal = () => { setShowAddCashinRequestModal(false) }


    const handleConfirmCashinRequestModal = async (name,id) =>
    {
        await sendRefCashin(id,name)
        refresh();
        setShowAddCashinRequestModal(false);
    }     

  return (
    <>
    <AddCashinRequestModal
        show={showAddCashinRequestModal}
        handleClose={handleCancelCashinModal}
        handleClick={handleConfirmCashinRequestModal}
        id={id}
        phoneNumber={phoneNumber}
        receipt={receipt}
    />

      <Row>
        <Col md="12">
          <Card className="card-plain table-plain-bg">
            <Card.Body className="table-full-width table-responsive px-0">
              <Table id="cashin">
                <thead>
                  <tr>
                    <th className="border-0">Id</th>
                    <th className="border-0">N° TELEPHONE</th>
                    <th className="border-0">REÇU</th>
                    <th className="border-0">AMOUNT</th>
                    <th className="border-0">ENVOYEZ LE REF</th>
                  </tr>
                </thead>
                <tbody>
                  {data.slice(0).reverse().map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{row.id}</td>
                        <td>{row.phoneNumber} </td>
                        <td>
                          <a
                            href={row.receipt}
                            target="_blank"
                            rel="noopener"
                          >
                            <img
                              src={row.receipt}
                              height="80"
                              width="50"
                            />
                          </a>
                        </td>
                        <td>{row.amount+" Dh"} </td>
                        <td>
                        {<Button 
                                onClick={() => {
                                    setPhoneNumber(row.phoneNumber);
                                    setReceipt(row.receipt);
                                    setId(row.id)
                                    setShowAddCashinRequestModal(true);
                                }} 
                                variant="success"
                                >
                                ENVOYEZ
                                </Button>

                            }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CashinRequestTable;
