import {
  Button,
  Modal,
  Table,
  Dropdown,
  Row,
  Col
} from "react-bootstrap";
import React, { useState } from "react";

const renderImeis = (imeis) => {

  if (imeis.length > 0)
    return (
      <>
        Imeis to delete
        <Table id="invoice">
              <thead>
                <tr>
                  <th className="border-0">imei</th>
                  <th className="border-0">Used At</th>
                  <th className="border-0">Shared With</th>
                </tr>
              </thead>
              <tbody>
                {
                  imeis.map((imei, index) => 
                  {
                    return (
                      <tr key={index}>
                        <td>{imei.imei}</td>
                        <td>{imei.usedAt}</td>
                        <td>{imei.sharedWith}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
        <hr></hr>
      </>
    );
}

export const ConfirmDeleteImeisModal = ({show, user, imei, handleClose, handleClick}) => {

  if (!user)
    return<></>;
  var imeis = user.imeis;
  if (imei)
    imeis = [imei];
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>confirm deleting user {user.phoneNumber} imeis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderImeis(imeis)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="danger" onClick={()=>handleClick(user, imei)}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
