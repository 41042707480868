/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import UsersListView from 'views/UsersListView';
import CashinDemandView from 'views/CashinDemandView';
import FormGenerator from 'views/formGenerator';


const dashboardRoutes = [
  {
    name: "utilisateur",
    path: "/Users/",
    icon: "nc-icon nc-chart-bar-32",
    component: UsersListView,
    layout: "/admin",
    sidebarVisibility: "visible",
  },
  {
    name: "Demande de dépôt",
    path: "/cashing/",
    icon: "nc-icon nc-chart-bar-32",
    component: CashinDemandView,
    layout: "/admin",
    sidebarVisibility: "visible",
  },
  { 
    name: "FormGenerator",
    path: "/FormGenerator/",
    icon: "nc-icon nc-chart-bar-32",
    component: FormGenerator,
    layout: "/admin",

  },

];

export default dashboardRoutes;
