import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export const getFormQuestions = async (phoneNumber) => {
  const token = await firebase.auth().currentUser.getIdToken();

  let url = `/3cx/Questions?phoneNumber=${encodeURIComponent(
    phoneNumber
  )}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 && response.data) {
      const data = response.data;
      console.log("data = ", data)
      return data;
    }
  } catch (e) {
    console.log("Error while getting questions");
  }
  return null;
};


export const sendAnswers = async (payload) => {
    const token = await firebase.auth().currentUser.getIdToken();
  
    let url = `3cx/Answers`;
    console.log(payload);
    try {
      const response = await axios.post(url,payload,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.success) {
        alert('Bien envoyé')
        return true;
      }else{
        alert('Erreur lors d\'envoyer les réponses')
      }
    } catch (e) {
      alert('Erreur lors d\'envoyer les réponses')
    }
    return false;
  };
