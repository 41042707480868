import {
  Button,
  Modal,
  Form,
  Dropdown,
  Row,
  Col
} from "react-bootstrap";
import React, { useState } from "react";
import constants from "../../constants";

const renderBlockParameters = (user, selected, setSelected, blockImei, setBlockImei) => {

  if (user.status == 'BLOCKED')
    return <></>
  const list =  [
    [1, '1 hour'],
    [12, '12 hours'],
    [24, '1 day'],
    [24 * 2, '2 days'],
    [24 * 3, '3 days'],
    [0, "forever"]
  ]
    return <Row col="10" style={{ alignItems: "flex-end" }}>
      <Col md="4">
        <p>Block Duration</p>
      </Col>
      <Col md="3">
        <Dropdown onSelect={(e)=> setSelected(e)}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
              {list.find(x => x[0] == selected)[1]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              list.map((duration, index) => {
                let hours = duration[0],
                    value = duration[1];
                return <Dropdown.Item key={index} eventKey={hours}  active={selected == hours} >{value}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col md="3">
        <input type="checkbox" checked={blockImei} onChange={(e) => setBlockImei(e.target.checked)}></input>
        <label>Block Imei</label>
      </Col>
    </Row>
}

const renderBlockNote = (user, note, setNote) =>
{
  if (user.status == 'BLOCKED')
        return <></>
  return <>
          <Form.Label>Note</Form.Label>
          <Form.Control as="textarea" rows={3} value={note} onChange={e => setNote(e.target.value)}/>
        </>
}

const renderBlockReason = (user, reasonIndex, setReasonIndex) => {
  if (user.status == 'BLOCKED')
        return <></>
  return (
    <Dropdown style={{marginBottom:12}} >
      <Dropdown.Toggle variant="success" id="dropdown-basic">Block Reason</Dropdown.Toggle>
      <Dropdown.Menu>
        {
          constants.Fraud.map((reason, index) => {
            return <Dropdown.Item key={index} eventKey={index} >
                      <input key={Math.random()}
                        type="checkbox"
                        checked={reasonIndex == index}
                        onChange={(e) => setReasonIndex(index)}
                      />
                      {reason}
                  </Dropdown.Item>
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}
export const ConfirmBlockModal = ({show, user, handleClose, handleClick}) => {
  const [selected, setSelected] = useState(1);
  const [blockImei, setBlockImei] = useState(false);
  const [note, setNote] = useState('');
  const [reasonIndex, setReasonIndex] = useState(0);

  if (!user)
    return<></>;
let buttonCaption = 'Block'
if (user.status == 'BLOCKED') buttonCaption = 'unblock'
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{buttonCaption} user {user.phoneNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderBlockParameters(user, selected, setSelected, blockImei, setBlockImei)}
          {renderBlockNote(user, note, setNote)}
          {renderBlockReason(user, reasonIndex, setReasonIndex)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={()=>handleClick(user, selected, blockImei, note, constants.Fraud[reasonIndex])}>{buttonCaption}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
